import { useEffect, useState } from "react";

import "./App.css";
import logo from "./logo.png";

import AppMainForm from "./AppMainForm";
import AppLeadForm from "./AppLeadForm";
import AppFooterSocials from "./AppFooterSocials";

const homeBackgroundImages = [
  "/images/home-bg-01.jpg",
  "/images/home-bg-02.jpg",
];

const bgInterval = 10 * 1000;

function App() {
  const [showForm, setShowForm] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleHideForm = () => {
    setShowForm(false);
  };

  const getBackgroundImage = () => {
    return `url(${homeBackgroundImages[currentImageIndex]})`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % homeBackgroundImages.length
      );
    }, bgInterval);

    return () => clearInterval(interval);
  }, []);

  return (
    <main
      className="App homeBackground overlay"
      style={{ backgroundImage: getBackgroundImage() }}
    >
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>

      {showForm ? (
        <AppLeadForm onButtonClick={handleHideForm} />
      ) : (
        <AppMainForm onButtonClick={handleShowForm} />
      )}

      {!showForm ? (
        <footer className="App-footer">
          <AppFooterSocials />
          <p>
            Copyright &copy; {new Date().getFullYear()} Casanova | Todos los
            derechos reservados
          </p>
        </footer>
      ) : (
        <div />
      )}
    </main>
  );
}

export default App;
